.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: $primary;
  border-color: $primary;
  color: #fff;
}

// To better horizontally align inputs
.date-picker-field {
  .field-label {
    margin-right: .5rem;
    text-align: left;
  }
  
  .field-body {
    flex-grow: 3;
  }
}

.input.flatpickr-input {
  width: 8rem;
}

.aside-filters .input.flatpickr-input {
  width: 95%;
}

