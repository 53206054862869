.columns.is-reversed {
    flex-direction: row-reverse;
}

.icon.next-to-text {
    margin-right: .2rem;

    &.is-small {
      margin-right: .4rem;
    }
}

// Styles for a list item for e.g. empty search results
.no-items {
    font-style: italic;
    margin: 0 1rem;
    color: $grey-lighter;
    text-align: center;
}

.no-wrap {
  white-space: nowrap;
}

.has-cursor-default {
  cursor: default;
}

.spaced-section {
  margin-bottom: 1.5rem;
  
  &.is-small { margin-bottom: 1rem; }
  &.is-large { margin-bottom: 2rem; }
}

@media (min-width: $tablet) {
  .spaced-section {
    margin-bottom: 2rem;

    &.is-small { margin-bottom: 1.4rem; }
    &.is-large { margin-bottom: 3rem; }
  }
}

.break-all {
  word-break: break-all;
}

.is-truncated {
  text-overflow: ellipsis;
  word-break: break-all;
}

// Inset text
.is-inset {
  padding: 1rem;
  border-left: 5px solid $grey-dark;
  
  &.is-small {
    padding: .5rem;
  }
}

.relative-container {
  position: relative;
}
