.title,
.subtitle {
  font-family: $family-secondary;

  &.has-underline {
    padding-bottom: .5rem;
    border-bottom: 1px solid $hr-background-color;
  }
}

.is-3 + p { margin-top: -.3rem; }
.is-4 + p { margin-top: -.5rem; }
.is-5 + p { margin-top: -.5rem; }
.is-6 + p { margin-top: -.5rem; }

.title.is-6 {
  color: $white;
  font-weight: 500;
}

p + .title {
  margin-top: 2rem;
}

.title.is-5:not(:last-child),
.title.is-6:not(:last-child) {
  margin-bottom: 1rem;
}

.title.is-5:not(.is-spaced) + .subtitle,
.title.is-6:not(.is-spaced) + .subtitle {
  margin-top: -.5rem;
}

code {
  padding: 0;
  background: none;
  color: darken($text, 15%);

  em {
    color: $code-highlight;
  }
}

// 'select all col inputs' link - in table headers
.select-all-col-inputs {
  margin-top: -.2rem;
  display: block;
}

// Show more link - for truncated text
.show-more-link {
  word-break: keep-all;
  white-space: nowrap;
}
