$fancy-input-size: 22px;
$fancy-input-border-color: #aaa;
$fancy-input-selected-color: $primary;

.fancy-input-container {
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;

  + label {
    margin-left: .3rem;
    vertical-align: middle;
    display: inline; // Added to fix Edge wrapping of labels - undo if it breaks other things
  }

  input {
    position: absolute;
    top: 0;
    right: 105%;
  }
}

.fancy-input {
  width: $fancy-input-size;
  height: $fancy-input-size;
  border: 1px solid $fancy-input-border-color;
  background: none;
  border-radius: 4px;
  outline: none;
  position: relative;
  display: block;
  user-select: none;
  text-indent: $fancy-input-size * 2;

  &:before,
  &:after {
    text-indent: 0;
  }

  &:hover {
    border-color: $fancy-input-border-color;
  }

  &:focus {
    border-color: $fancy-input-selected-color;
    box-shadow: inset 0 0 2px $fancy-input-selected-color;
  }
}

.fancy-input-container input {
  &:disabled + label {
    opacity: 0.35;

    &:hover,
    &:focus {
      border-color: rgba($fancy-input-border-color, .8);
      box-shadow: none;
    }
  }

  &[readonly] + label {
    border-color: transparent !important;
    background: $readonly-bg;
    opacity: 1 !important;
  }
}

.fancy-checkbox {
  // 'intermediate' bar
  &:before {
    content: '';
    width: 60%;
    height: 2px;
    background: $fancy-input-border-color;
    left: 19%;
    top: 50%;
    margin-top: -1px;
    position: absolute;
    opacity: 0;
    transform: rotate(-45deg);
    transition: transform 0.2s cubic-bezier(.59, .35, .33, 1), opacity .1s linear;
  }

  // 'check' mark
  &:after {
    content: '\f00c';
    font: $icon-font;
    text-rendering: auto;
    position: absolute;
    left: 25%;
    top: 25%;
    font-size: 70%;
    color: $fancy-input-selected-color;
    opacity: 0;
    transform: rotate(-45deg);
    transform-origin: 35% 75%;
    transition: transform 0.2s cubic-bezier(.59, .35, .33, 1), opacity .1s linear;
  }

  &.is-info:after { color: $info; }
  &.is-warning:after { color: $warning; }
  &.is-danger:after { color: $danger; }
  &.is-intermediate {
    &:before {
      opacity: 1;
      transform: rotate(0);
    }
  }
}

.fancy-input-container input:checked + .fancy-checkbox,
.fancy-checkbox.is-selected,
tr.is-selected .fancy-checkbox {
  &:before {
    opacity: 0;
    transform: rotate(45deg);
  }

  &:after {
    opacity: 1;
    transform: rotate(0);
  }
}

.fancy-radio {
  border-radius: $fancy-input-size * 2;
  perspective: $fancy-input-size * 2;

  &:after {
    content: '';
    position: absolute;
    width: ($fancy-input-size - 2) / 2;
    height: ($fancy-input-size - 2) / 2;
    left: ($fancy-input-size - 2) / 4;
    top: ($fancy-input-size - 2) / 4;
    background: $primary;
    border-radius: $fancy-input-size * 2;
    opacity: 0;
    transform: rotate3d(-1, -1, -1, -270deg);
    transition: transform 0.3s cubic-bezier(0, .6, .35, .9), opacity .1s linear;
  }
}

.fancy-input-container input:checked + .fancy-radio,
.fancy-radio.is-selected,
tr.is-selected .fancy-radio {
  &:after {
    opacity: 1;
    transform: rotate3d(0, 0, 0, 0);;
  }
}

// For an expandable field that usually follows a fancy input & label
.fancy-input-moreinfo {
  margin-top: 0.6rem;
  margin-left: 1.9rem;
}