.field.is-horizontal {
  align-items: center;

  .field-body {
    flex-wrap: wrap;
  }
}

.field-label {
  line-height: 1.2;
}

label.radio + label.radio {
  margin-left: inherit;
}

.dropdown-divider.is-small {
  margin: .5rem 1rem;
}

.hero.is-primary .tabs li.is-active a {
  color: $primary !important;
}

.hero.is-info .tabs li.is-active a {
  color: $info !important;
}
/*
a.navbar-item.is-active  {
  color: #567d46 !important;
}*/