/*
 * Bulma variables
 * Visit https://bulma.io/documentation/customize/variables/ for reference variables.
 * Individual component variables can be found here: https://github.com/jgthms/bulma/tree/master/sass/elements
*/

//$family-sans-serif: 'Assistant', sans-serif;
//$family-secondary: 'Rubik', 'serif';

$desktop: 1200px;
$widescreen: 1400px;
$fullhd: 1600px;

$size-1: 2.7rem;
$size-2: 2.3rem;
$size-3: 1.8rem;
$size-4: 1.4rem;
$size-5: 1.15rem;
$size-6: .94rem;
$size-7: 0.75rem;

$code-highlight: #f41a7e;

$readonly-bg: rgba(0,0,0,.6);
$readonly-border: transparent;

$radius-small: 2px;
$radius: 2px;
$radius-large: 5px;
$hr-height: 1px;
$hr-background-color: rgba(0,0,0,.07);

$turquoise: #567d46;
$link: #567d46;
$cyan: #567d46;
$dark: #466539;
$black: #1d2830;
