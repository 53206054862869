@charset "utf-8";


// Import variables and important preload files
@import 'variables/bulma';
@import 'variables/variables';


// Import plugins
@import 'bulma';
@import 'izitoast/dist/css/iziToast.css';
@import 'flatpickr/dist/flatpickr.css';
@import 'flatpickr/dist/themes/airbnb.css';


// Import plugin overrides
@import 'plugin-overrides/izitoast';
@import 'plugin-overrides/datatables';
@import 'plugin-overrides/flatpickr';
@import 'plugin-overrides/bulma';


// Import common styles
@import 'common/animations';
@import 'common/base';
@import 'common/type';
@import 'common/form';
@import 'common/nav';
@import 'common/helpers';
@import 'common/button';
@import 'common/table';
@import 'common/list';


// Import component styles
@import 'components/fancy-input';
@import 'components/fix-on-scroll';
@import 'components/modal';
@import 'components/misc';


// Import page-specific styles
//@import 'pages/login';
