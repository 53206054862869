.modal {
  display: flex;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: opacity .12s linear;
  perspective: 20rem;

  .modal-close {
    pointer-events: none;
  }

  &.is-active {
    opacity: 1;
    user-select: inherit;
    pointer-events: all;

    .modal-close {
      pointer-events: auto;
    }
  }
}

.modal-content {
  padding-left: 1rem;
  padding-right: 1rem;
  transition: transform .25s cubic-bezier(.15,.34,.37,1);
  transform: rotateX(15deg) translateZ(-0.5rem) translateY(-4rem);
}
.modal.is-active .modal-content {
  transform: none;
}

.modal-background {
  background-color: rgba(14,16,16,.9);
}

.modal-close {
  transition: background-color 0.1s linear;
}

.modal-close:focus,
.modal-close:hover {
  background: rgba(255,255,255,0.3);
}
