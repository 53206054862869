::selection {
  background: $primary-dark;
  color: $white-ter;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 5px;
}

html {
  font-size: 14px;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
}

#page-wrapper {
  flex: 1 0 auto;
}

#skip-to-content-link {
  position: fixed;
  bottom: .8rem;
  left: -20rem;
  z-index: 40;
  padding: 1.5rem;

  &:focus {
    left: .7rem;
  }
}

.footer {
  padding: 1.8rem 1.5rem 2.8rem;

  p:first-child {
    margin-bottom: .4rem;
  }
}

hr.is-small {
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
}

summary {
  cursor: pointer;
  display: inline-block;
  color: $primary !important;
}

a.is-loading {
  pointer-events: none;
  color: transparent;
  position: relative;

  &:after {
    @extend %loading-icon-pseudo;
  }
}

.separator {
  margin: 0 .3rem;
  color: rgba(0,0,0,.3);
}

@media (min-width: $tablet) {
  html {
    font-size: 16px;
  }
}
