.table.is-fixed {
  table-layout: fixed;
}

.table.truncate-last-row td:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table thead th {
  line-height: 1.2;
}

tr.no-content th,
tr.no-content td {
  text-align: center;
  font-style: italic;
  padding: 1rem;
  background: $black;
}


// div in a table that contains a .loading-icon.is-large
.table-col-loading {
  text-align: center;
  flex-grow: 1;
  padding: 2rem 1rem;
  background: rgba(0,0,0,0.14);
}


// Ensure normal tables are covered as well as .table
table.is-fullwidth {
  width: 100%;
}

// Can also be used for 'empty' divs that aren't in a table
/* @TODO - May not be required for cibase
.empty-table-content {
  text-align: center;
  padding: 2.5rem 2.5rem 3.2rem;
  background: $black-bis;

  img {
    margin-bottom: .5rem;
    width: 12rem;
    max-height: 10rem;
  }
}
.empty-table-text {
  font-size: 1.4rem;
  color: #666;
  font-style: normal;
}
*/