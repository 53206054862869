// Many of DataTables's default classes use underscores and camel case. Custom classes use dashes.

$dt-loading-icon-size: 3rem;

.datatable {
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    pointer-events: none;
    opacity: 0;
    animation-delay: 0.5s;
    transition: opacity 0.2s linear 0.15s;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #eee;
  }

  &:after {
    @extend %loading-icon-pseudo;

    z-index: 3;
    width: $dt-loading-icon-size;
    height: $dt-loading-icon-size;
    top: calc(50% - #{$dt-loading-icon-size / 2});
  }

  &.is-loading {
    &:before {
      opacity: 1;
      pointer-events: inherit;
      animation-delay: 0s;
      transition-delay: 0s;
    }

    &:after {
      opacity: 1;
      animation-delay: 0s;
      transition-delay: 0s;
    }
  }
}

.datatable-header {
  background: $white-ter;
  margin-bottom: .7rem;
  position: relative;

  .columns {
    margin: 0;
  }

  .column:last-child {
    text-align: right;
  }
}

.dataTables_empty {
  text-align: center !important;
  font-style: italic;
  padding: 3rem 1rem !important;
  background: #f6f6f6;
}

.dataTables_paginate {
  @extend .pagination;

  margin: 0;
  justify-content: center;

  > span {
    @extend .pagination-list;

    flex-grow: 0;

    a {
      @extend .pagination-link;

      &.current {
        // Removed because compound selectors can no longer be extended - http://bit.ly/ExtendCompound
        //@extend .pagination-link.is-current;
        background-color: $primary;
        border-color: $primary;
        color: $primary-invert;
      }
    }
  }
}

.paginate_button.previous {
  @extend .pagination-previous;
}
.paginate_button.next {
  @extend .pagination-next;
}
.paginate_button.disabled {
  background: #ccc;
  color: #777;
  pointer-events: none;
}
.ellipsis {
  @extend .pagination-ellipsis;
}

table thead .sorting,
table thead .sorting_asc,
table thead .sorting_desc {
  position: relative;
  cursor: default;
  color: $primary;
  padding-right: 1.4rem;
  border-radius: 5px 5px 0 0;

  &:hover {
    background-color: rgba(255,255,255,0.04);
  }

  &:after {
    position: absolute;
    top: calc(50% - .3rem);
    right: .4rem;
    font: $icon-font;
    content: '\00a0\f077';
    transition: opacity 0.1s linear, transform 0.12s ease;
    color: $primary;
  }
}
th.sorting:after {
  opacity: 0;
}
th.sorting_asc:after {
  opacity: 1;
}
th.sorting_desc:after {
  opacity: 1;
  transform: scaleY(-1);
}

th.sorting_desc,
th.sorting_asc {
  background: rgba(255,255,255,0.04);
}
