// Fix for IE11/Edge dropdowns
@media only screen and (min-width: $tablet) {
  .navbar-item.has-dropdown.is-active > .navbar-dropdown {
    display: block;
  }
}

#page-breadcrumb {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

.navbar-divider {
  margin-left: 1rem;
  margin-right: 1rem;
}

.navbar-link:not(.is-arrowless)::after {
  transition: transform .15s ease;

  .is-active & {
    transform: translateY(3px) rotate(-225deg);
  }
}

.tabs {
  &.has-active-dropdown {
    overflow-y: visible;
    overflow-x: visible;
  }

  .dropdown-trigger {
    a { padding-right: .5rem; }
    .icon { transition: transform 0.15s ease-out; }
  }

  .dropdown.is-active .dropdown-trigger .icon {
    transform: rotate(-180deg);
  }
}
