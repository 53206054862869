.label:not(:last-child) {
    margin-bottom: .3rem;
}

.field:not(:last-child) {
    margin-bottom: 1.3rem;
}

.form-section {
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.input[size] {
  width: auto;
}

.stacked-input-field {
  margin-bottom: .5rem;
}

.field-icon {
  float: right;
  margin-right: 10px;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.field-icon:hover {
  cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
