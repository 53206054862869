.simple-list {
  list-style: none;
  
  > li {
    margin-bottom: .3rem;
    
    > .icon {
      margin-right: .15rem;
    }
  }
}

.block-list {
  display: inline-block;
  
  &.is-fullwidth {
    display: block;
  }
  
  > li {
    padding: .4rem 3rem .4rem .8rem;
    position: relative;
    background: rgba(0,0,0,0.3);
    
    &:nth-child(even) {
      background: rgba(0,0,0,0.15);
    }
  }
  
  .remove-btn {
    position: absolute;
    top: 50%;
    right: .5rem;
    margin-top: -0.7rem;
    font-size: 1rem;
  }
}

.inline-dl {
  display: grid;
  grid-column-gap: 0;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 1rem;
  
  dt {
    font-weight: 600;
    color: $white;
  }
  
  dt,dd {
    border-bottom: 1px solid $grey-darker;
  }
  
  &.is-light {
    dt { color: $black; }
    dt,dd {
      border-bottom-color: $grey-lighter;
    }
  }
  
  dt.is-last,
  dd.is-last {
    border-bottom: 0;
  }
}

.inline-dl-total {
  padding: .2rem 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: $white;
  border-bottom: 0 !important;
}
