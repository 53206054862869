.agent-version-details {
  font-size: .9rem;
  line-height: 1.3;
}


// --- Loading icon ---
@keyframes loadingIconPrimary {
  0% {
    box-shadow: inset 0 0 0 5px rgba($primary, 1), 7px 0 0 rgba($primary, 0.3), 14px 0 0 rgba($primary, 0.3);
  }
  
  25% {
    box-shadow: inset 0 0 0 5px rgba($primary, 0.3), 7px 0 0 rgba($primary, 1), 14px 0 0 rgba($primary, 0.3);
  }
  
  50% {
    box-shadow: inset 0 0 0 5px rgba($primary, 0.3), 7px 0 0 rgba($primary, 0.3), 14px 0 0 rgba($primary, 1);
  }
  
  75% {
    box-shadow: inset 0 0 0 5px rgba($primary, 0.3), 7px 0 0 rgba($primary, 0.3), 14px 0 0 rgba($primary, 0.3);
  }
  
  100% {
    box-shadow: inset 0 0 0 5px rgba($primary, 1), 7px 0 0 rgba($primary, 0.3), 14px 0 0 rgba($primary, 0.3);
  }
}

@keyframes loadingIconPrimaryLarge {
  0% {
    box-shadow: inset 0 0 0 12px rgba($primary, 1), 14px 0 0 rgba($primary, 0.3), 28px 0 0 rgba($primary, 0.3);
  }
  
  25% {
    box-shadow: inset 0 0 0 12px rgba($primary, 0.3), 14px 0 0 rgba($primary, 1), 28px 0 0 rgba($primary, 0.3);
  }
  
  50% {
    box-shadow: inset 0 0 0 12px rgba($primary, 0.3), 14px 0 0 rgba($primary, 0.3), 28px 0 0 rgba($primary, 1);
  }
  
  75% {
    box-shadow: inset 0 0 0 12px rgba($primary, 0.3), 14px 0 0 rgba($primary, 0.3), 28px 0 0 rgba($primary, 0.3);
  }
  
  100% {
    box-shadow: inset 0 0 0 12px rgba($primary, 1), 14px 0 0 rgba($primary, 0.3), 28px 0 0 rgba($primary, 0.3);
  }
}

@keyframes loadingIconOnWhite {
  0% {
    box-shadow: inset 0 0 0 5px rgba($black, 1), 7px 0 0 rgba($black, 0.3), 14px 0 0 rgba($black, 0.3);
  }
  
  25% {
    box-shadow: inset 0 0 0 5px rgba($black, 0.3), 7px 0 0 rgba($black, 1), 14px 0 0 rgba($black, 0.3);
  }
  
  50% {
    box-shadow: inset 0 0 0 5px rgba($black, 0.3), 7px 0 0 rgba($black, 0.3), 14px 0 0 rgba($black, 1);
  }
  
  75% {
    box-shadow: inset 0 0 0 5px rgba($black, 0.3), 7px 0 0 rgba($black, 0.3), 14px 0 0 rgba($black, 0.3);
  }
  
  100% {
    box-shadow: inset 0 0 0 5px rgba($black, 1), 7px 0 0 rgba($black, 0.3), 14px 0 0 rgba($black, 0.3);
  }
}

.loading-icon,
time:empty {
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  border-radius: 1000px;
  background: 0 0;
  box-shadow: inset 0 0 0 5px $primary, 7px 0 0 $primary, 14px 0 0 $primary;
  animation: loadingIconPrimary 0.8s linear 0s infinite forwards;
  
  &.is-large {
    width: 8px;
    height: 8px;
    box-shadow: inset 0 0 0 12px $primary, 14px 0 0 $primary, 28px 0 0 $primary;
    animation: loadingIconPrimaryLarge 0.8s linear 0s infinite forwards;
  }
}

time:empty {
  margin-right: 1.5rem;
  margin-left: .5rem;
}
